
/** ADD YOUR AWESOME CODES HERE **/

.emp-profile{
    padding: 3%;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 0.5rem;
    background: #fff;
}
.profile-img{
    text-align: center;
}
.profile-img img{
    width: 70%;
    height: 100%;
}
.profile-img .file {
    position: relative;
    overflow: hidden;
    margin-top: -20%;
    width: 70%;
    border: none;
    border-radius: 0;
    font-size: 15px;
    background: #212529b8;
}
.profile-img .file input {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
}
.profile-head h5{
    color: #333;
}
.profile-head h6{
    color: #d01616;
}
.profile-edit-btn{
    border: none;
    border-radius: 1.5rem;
    width: 70%;
    padding: 2%;
    font-weight: 600;
    color: #6c757d;
    cursor: pointer;
}
.proile-rating{
    font-size: 12px;
    color: #818182;
    margin-top: 5%;
}
.proile-rating span{
    color: #495057;
    font-size: 15px;
    font-weight: 600;
}
.profile-head .nav-tabs{
    margin-bottom:5%;
}
.profile-head .nav-tabs .nav-link{
    font-weight:600;
    border: none;
}
.profile-head .nav-tabs .nav-link.active{
    border: none;
    border-bottom:2px solid #d01616;
}
.profile-work{
    padding: 14%;
    margin-top: -15%;
}
.profile-work p{
    font-size: 12px;
    color: #818182;
    font-weight: 600;
    margin-top: 10%;
}
.profile-work a{
    text-decoration: none;
    color: #495057;
    font-weight: 600;
    font-size: 14px;
}
.profile-work ul{
    list-style: none;
}
.profile-tab label{
    font-weight: 600;
}
.profile-tab p{
    font-weight: 600;
    color: #d01616;
}

.custom_btn{
    color: white!important;
}
.custom_btn:hover{
    color: black!important;
}
.required{
    color: red;
}
.person{
    display: none;
}
.each_offer{
    margin-bottom: 100px;
}
.offer-head-text{
    font-size: 32px;
    font-weight: 300;
    line-height: 62px;
    margin: 0 0 25px;
    padding: 0;
    text-transform: none;
}

.hr-custom{
    display:block;
    border: 1px dashed #f2f3f5;
    z-index:-1;
    width:100%;
}

.custom-color{
    color: #d01616!important;
    font-weight: bold;
}

#thread1{
    top: 0.8em;
    -webkit-animation: thread2 2s;
    -moz-animation:    thread2 2s;
    -o-animation:      thread2 2s;
    animation:         thread2 2s;
}

@-webkit-keyframes thread2 {
    0%   { width: 100%; }
    50% { width: 100%; }
    80% { width: 0px;}
    100%{ width:100%;}
}
@-moz-keyframes thread2 {
    0%   { width: 100%; }
    50% { width: 100%; }
    80% { width: 0px;}
    100%{ width:100%;}
}
@-o-keyframes thread2 {
    0%   { width: 100%; }
    50% { width: 100%; }
    80% { width: 0px;}
    100%{ width:100%;}
}
@keyframes thread2 {
    0%   { width: 100%; }
    50% { width: 100%; }
    80% { width: 0px;}
    100%{ width:100%;}
}


.offer-item{
    color: #333;
    font-size: 20px;

}
.offer-item-highlight{
    color: #d01616;
}

@media (max-width: 768px) {
    .offer-item{
        font-size: 24px;

        float: none!important;
    }
    .btn-report{
        display:block;
        width: 100%;
        text-align: center;
    }
    .btn-buy{
        display:block;
        width: 100%;
        text-align: center;
    }
}
@media (max-width: 512px) {
    .offer-item{
        font-size: 18px;

        float: none!important;
    }

}

.contact{
    margin-bottom: 100px;
}
.bg-custom{
    background-color: #d01616;
}


.forgot-password-btn:hover{
    color: #d01616!important;
}

.custom-margin{
    margin-top: 150px;
    margin-bottom: 100px;
}

#offer-table_length{
    float: left!important;
}
#offer-table_info{
    float: left!important;
}



.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #d01616 !important;
    border-color: #d01616 !important;
}

.custom-a{
    color: #d01616 !important;
}

.custom-hr{
    width: 100%;
    color: white;
    background-color: white;
    opacity: 0.2;
}
.box-header{
    position: absolute;
    top: -20%;
    width: 200px;
    height: 100%;
    background: rgba(0,0,0,0.2);
}

@media (max-width: 1158px) {
    .box-header{
        position: absolute;
        top: -20%;
        width: 300px;
        height: 50%;
        background: rgba(0,0,0,0.2);
    }
}

@media (max-width: 578px) {
    .box-header{
        display: none;
    }

    .header-container{
        margin-top: 80px;
    }
    .header-bigger-text{
        font-size:40px;
    }
}

@media (max-width: 720px) {
    .header-container{
        margin-top: 80px;
    }

    .peak{
        margin-top: 20px!important;
        width: 100% !important;

    }

}

@keyframes pulse {
    from { transform: scale(1); }
    50% { transform: scale(0.85); }
    to { transform: scale(1); }
}

.fe-pulse {
    animation-name: pulse;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

.background-menu{
    background-color: #63839c!important;
}
.clickable-row{
    cursor: pointer;
}
.nav-tab-link{
    color: #d01616!important;
}

.no-padding-col{
    padding-right: 0!important;
}

@media
only screen and (max-width: 992px){
    .header_style_01{
        background-color: #63839c!important;
    }
    .header-hr{
        display: none!important;
    }
}


@media
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
    .mobile-counter{
        display: block!important;
    }
    .search-row-input{
        margin: 30px 0 0 !important;
    }
    .timeline-wrapper{
        display:none;
    }
    .counter-section{
        height: auto!important;
        padding-bottom: 100px!important;
    }
    .event{
        margin-bottom: 60px!important;
    }
    .text-peak{
        font-size: 16px!important;
        margin: 1rem!important;
    }
    .event:after{
        display:none!important;
    }
    .mobile-arrow{
        display: block!important;
        color: #ff143b!important;
    }
    .icon-arrow{
        margin-top: 0!important;
    }
    .icon-text{
        margin-top: 0!important;
    }
    .mobile-arrow-container{
        display: block!important;
    }
}
.mobile-arrow{
    display:none;
}
.mobile-arrow-container{
    display: none;
}
.mobile-counter{
    display:none;
}

.dropzone {
    background: white;
    border-radius: 5px;
    border: 2px dashed rgb(0, 135, 247);
    border-image: none;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}
#offer-table_filter{
    display:none!important;
}
