/*------------------------------------------------------------------
    Version: 1.0
-------------------------------------------------------------------*/


/*------------------------------------------------------------------
    [Table of contents]

    1. IMPORT FONTS
    2. IMPORT FILES
    3. SKELETON
    4. WP CORE
    5. HEADER
    6. SECTIONS
    7. SECTIONS
    8. PORTFOLIO
    9. TESTIMONIALS
    10. PRICING TABLES
    11. ICON BOXES
    12. MESSAGE BOXES
    13. FEATURES
    14. CONTACT
    15. FOOTER
    16. MISC
    17. BUTTONS
-------------------------------------------------------------------*/


/*------------------------------------------------------------------
    IMPORT FONTS
-------------------------------------------------------------------*/

@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700');

/*------------------------------------------------------------------
    IMPORT FILES
-------------------------------------------------------------------*/

@import url(animate.css);
@import url(flaticon.css);
@import url(prettyPhoto.css);
@import url(owl.carousel.css);
@import url(font-awesome.min.css);

/*------------------------------------------------------------------
    SKELETON
-------------------------------------------------------------------*/

body {
    color: #999;
    font-size: 15px;
    font-family: 'Ubuntu', sans-serif; 
    line-height: 1.7;
    overflow-x:hidden;
}

body.demos .section {
    background: url(../images/bg.png) repeat top center #f2f3f5;
}

body.demos .section-title img {
    max-width: 280px;
    display: block;
    margin: 10px auto;
}

body.demos .service-widget h3 {
    border-bottom: 1px solid #ededed;
    font-size: 18px;
    padding: 20px 0;
    background-color: #ffffff;
}

body.demos .service-widget {
    margin: 0 0 30px;
    padding: 30px;
    background-color: #fff
}

body.demos .container-fluid {
    max-width: 1080px
}

a {
    color: #1f1f1f;
    text-decoration: none !important;
    outline: none !important;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    letter-spacing: 0;
    font-weight: normal;
    position: relative;
    padding: 0 0 10px 0;
    font-weight: normal;
    line-height: 120% !important;
    color: #121212;
    margin: 0
}

h1 {
    font-size: 24px
}

h2 {
    font-size: 22px
}

h3 {
    font-size: 18px
}

h4 {
    font-size: 16px
}

h5 {
    font-size: 14px
}

h6 {
    font-size: 13px
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: #212121;
    text-decoration: none!important;
    opacity: 1
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
    opacity: .8
}

a {
    color: #1f1f1f;
    text-decoration: none;
    outline: none;
}

a,
.btn {
    text-decoration: none !important;
    outline: none !important;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.btn-custom {
    margin-top: 20px;
    background-color: transparent !important;
    border: 2px solid #ddd;
    padding: 12px 40px;
    font-size: 16px;
}

.lead {
    font-size: 18px;
    line-height: 30px;
    color: #767676;
    margin: 0;
    padding: 0;
}

blockquote {
    margin: 20px 0 20px;
    padding: 30px;
}

ul, li, ol{
	margin: 0px;
	padding: 0px;
	list-style: none;
}


/*------------------------------------------------------------------
    WP CORE
-------------------------------------------------------------------*/

.first {
    clear: both
}

.last {
    margin-right: 0
}

.alignnone {
    margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright {
    float: right;
    margin: 10px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 10px 20px 20px 0;
}

a img.alignright {
    float: right;
    margin: 10px 0 20px 20px;
}

a img.alignnone {
    margin: 10px 20px 20px 0;
}

a img.alignleft {
    float: left;
    margin: 10px 20px 20px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto
}

.wp-caption {
    background: #fff;
    border: 1px solid #f0f0f0;
    max-width: 96%;
    /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}

.wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
}


/* Text meant only for screen readers. */

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.screen-reader-text:focus {
    background-color: #f1f1f1;
    border-radius: 3px;
    box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
    clip: auto !important;
    color: #21759b;
    display: block;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: bold;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */
}

/*------------------------------------------------------------------
    Preloader
-------------------------------------------------------------------*/

#cupcake{
	flex-direction:row;
	-webkit-flex-direction:row;
	-ms-flex-direction:row;
	-mos-flex-direction:row;
	-o-flex-direction:row;
	justify-content:center;
	-webkit-justify-content:center;
	-ms-justify-content:center;
	height:100%;
	width:100%;
	background-color:#ff143b;
}

.letter{
    font-size:100px;
    color:#222;
    font-family:tahoma;
}

.box{
    display: box;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

.cupcakeCircle, .cupcakeInner, .cupcakeCore{
     border-radius:50%;
    -webkit-border-radius:50%;
    -moz-border-radius:50%;
    -ms-border-radius:50%;
}

.cupcake, .letter, .cupcakeCircle, .cupcakeInner, .cupcakeCore{
    flex:none;
    -webkit-flex:none;
    -moz-flex:none;
    -ms-flex:none;
    -o-flex:none;
}

.letter, .cupcakeCircle{
    align-self:center;
    -webkit-align-self:center;
    -moz-align-self:center;
    -o-align-self:center;
    -ms-align-self:center;
}
.cupcakeCircle{
    align-items:center;
    -ms-align-items:center;
    justify-content:center;
    -ms-justify-content:center;
    height:100px;
    width:100px;
    background-color:#222;
}

.cupcakeInner{
    align-self:center;
    -ms-align-self:center;
    justify-content:center;
    -ms-justify-content:center;
    height:50%;
    width:50%;
    background-color:#ff143b;
    -webkit-animation-name:cupcakeAnimate;
    -webkit-animation-duration:500ms;
    -webkit-animation-direction:alternate;
    -webkit-animation-timing-function:ease-in-out;
    -webkit-animation-iteration-count:infinite;
    
}
.cupcakeCore{
    align-self:center;
    -ms-align-self:center;
    height:25%;
    width:25%;
    background-color:#222;
    -webkit-animation-name:coreAnimate;
    -webkit-animation-duration:1s;
    -webkit-animation-direction:alternate;
    -webkit-animation-timing-function:ease-in-out;
    -webkit-animation-iteration-count:infinite;
}

@-webkit-keyframes cupcakeAnimate{
        to{ height:90%; width:90%; }
}
@keyframes cupcakeAnimate{
    to{ height:90%; width:90%; }
}

@-webkit-keyframes coreAnimate{
    to{ height:90%; width:90%; }
}
@keyframes coreAnimate{
    to{ height:90%; width:90%; }
}



/*------------------------------------------------------------------
    HEADER
-------------------------------------------------------------------*/

.megamenu .nav,
.megamenu .collapse,
.megamenu .dropup,
.megamenu .dropdown {
    position: static;
}

.megamenu .container-fluid {
    position: relative;
}

.megamenu .dropdown-menu {
    left: auto;
}

.megamenu .megamenu-content {
    padding: 20px 30px;
}

.megamenu .dropdown.megamenu-fw .dropdown-menu {
    left: 0;
    right: 0;
}

.megamenu .list-unstyled {
    min-width: 200px;
}

.header_style_01 {
    background-color: transparent;
    display: block;
    left: 0;
    padding: 20px 40px;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 111;
}

.header_style_01 .navbar-default {
    background-color: transparent;
    border: 0;
}

.header_style_01 .navbar,
.header_style_01 .navbar-nav,
.header_style_01 .navbar-default,
.header_style_01 .nav {
    margin-bottom: 0 !important;
}

.header_style_01 .navbar-brand {
    padding: 2px 15px 0 15px;
	height: auto;
}

.header_style_01 .navbar-default .navbar-nav >  a {
    border-radius: 0;
    color: #f2f3f5;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
    background-color: transparent;
}

.header_style_01 .navbar-default .navbar-nav > a {
    background-color: transparent;
	font-weight: 500;
	padding: 7px 17px 7px;
	-webkit-border-radius: 3px;
	-ms-border-radius: 3px;
	border-radius: 3px;
	line-height: 21px;
}

.header_style_01 .navbar-default .navbar-nav > a:hover,
.header_style_01 .navbar-default .navbar-nav > a:focus  {
    background-color: #84a8c2;
	color: #ffffff;
}

.header_style_01 .navbar-default .navbar-nav > a.active{
	background-color: #84a8c2;
	color: #ffffff;
}

.header_style_01 .navbar-right > a{
	margin-left: 10px;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.header_style_01 .navbar-right  > a {
    padding-bottom: 10px;
    padding-top: 10px;
}

a.social-links {
    margin: 0 8px;
}

a.social-links  {
    padding: 13px 0 !important;
}

.affix-top {
    overflow: hidden;
    visibility: visible;
    opacity: 1;
    top: -100%;
}

.navbar-nav a {
    position: relative;
}

.navbar-nav span {
    font-size: 24px;
    position: absolute;
    right: 2px;
    top: 13px;
}

.header_style_01 .navbar-default .navbar-nav > a.btn-light.top-btn{
	position: relative;
	line-height: 24px;
	color: #ffffff;
	font-size: 14px;
	font-weight: 700;
	padding: 9px 26px;
	border-radius: 50px;
	letter-spacing: 1px;
	margin-top: 16px;
	background-color: #ff143b;
	border: 2px solid #ff143b;
	transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	-webkit-transition: all 0.3s ease;
	-ms-transition: all 0.3s ease;
	-o-transition: all 0.3s ease;
}

.header_style_01 .navbar-default .navbar-nav > a.btn-light.top-btn:hover{
	color: #ff143b;
	border-color: #ff143b;
	background: none;
}

.menu-top{
	margin-top: 22px;
}

.fixed-menu .navbar-default{
    background: #63839c;
	position: fixed;
	visibility: hidden;
	left: 0px;
	top: 0px;
	width: 100%;
	padding: 0px 0px;
	z-index: 0;
	transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-webkit-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	z-index: 999;
	opacity: 1;
	visibility: visible;
	-ms-animation-name: fadeInDown;
	-moz-animation-name: fadeInDown;
	-op-animation-name: fadeInDown;
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
	-ms-animation-duration: 500ms;
	-moz-animation-duration: 500ms;
	-op-animation-duration: 500ms;
	-webkit-animation-duration: 500ms;
	animation-duration: 500ms;
	-ms-animation-timing-function: linear;
	-moz-animation-timing-function: linear;
	-op-animation-timing-function: linear;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-ms-animation-iteration-count: 1;
	-moz-animation-iteration-count: 1;
	-op-animation-iteration-count: 1;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}

.fixed-menu .navbar-default{
	padding: 15px 0px;
}


.custom-header-background{
    background: rgba(32,55,152,0.6);
    background: -moz-linear-gradient(top, rgba(32,55,152,0.6) 0%, rgba(17,93,131,0.25) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(32,55,152,0.6)), color-stop(100%,rgba(17,93,131,0.25)));
    background: -webkit-linear-gradient(top, rgba(32,55,152,0.6) 0%,rgba(17,93,131,0.25) 100%);
    background: -o-linear-gradient(top, rgba(32,55,152,0.6) 0%,rgba(17,93,131,0.25) 100%);
    background: -ms-linear-gradient(top, rgba(32,55,152,0.6) 0%,rgba(17,93,131,0.25) 100%);
    background: linear-gradient(to bottom, rgba(32,55,152,0.6) 0%,rgba(17,93,131,0.25) 100%);
}
.overlay{
    opacity: 0;
    background-size: cover;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out;
    width: 100%;
    height: 100%;
}


/*------------------------------------------------------------------
    SECTIONS
-------------------------------------------------------------------*/

.parallax {
    background-attachment: fixed;
    background-size: cover;
    height: 100%;
    padding: 120px 0;
    position: relative;
    width: 100%;
}

.parallax.parallax-off {
    background-attachment: scroll !important;
    display: block;
    height: 100%;
    min-height: 100%;
    overflow: hidden;
    position: relative;
    background-position: center center;
    vertical-align: sub;
    width: 100%;
    z-index: 2;
}

.no-scroll-xy {
    overflow: hidden !important;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -ms-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
}

.section {
    display: block;
    position: relative;
    overflow: hidden;
    padding: 70px 0;
}

.noover {
    overflow: visible;
}

.noover .btn-dark {
    border: 0 !important;
}

.nopad {
    padding: 0;
}

.nopadtop {
    padding-top: 0;
}

.section.wb {
    background-color: #ffffff;
}

.section.lb {
    background-color: white;
}

.section.db {
    background-color: #1f1f1f;
}

.section.color1 {
    background-color: #448AFF;
}

.first-section {
    display: block;
    position: relative;
    overflow: hidden;
    padding: 16em 0 13em;
    background: url(../images/header.jpg) rgba(30, 83, 208, 0.47) no-repeat center center fixed;

    -webkit-background-size: cover;


    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-blend-mode: multiply;
}

.first-section h2 {
    color: #ffffff;
    font-size: 68px;
    font-weight: 300;
    text-transform: capitalize;
    display: block;
    margin: 0;
    position: relative;
}
.header-small-text{
    font-size: 27px;
}
.btn-custom-primary-outline{
    background: none;
    color: #fff;
    text-shadow: none;
    padding: 7px 20px;
    line-height: 1.6;
    box-shadow: none;
    font-size: 14px;
    font-weight: 700;
    border-radius: 3px;
    border: 2px solid #fff!important;
    transition: all .1s ease-in-out;
}

.btn-custom-outline-red{
    background: none;
    color: black;
    text-shadow: none;
    padding: 7px 20px;
    line-height: 1.6;
    box-shadow: none;
    font-size: 14px;
    font-weight: 700;
    border-radius: 3px;
    border: 2px solid #ff143b!important;
    transition: all .1s ease-in-out;
}

.btn-custom-outline-blue{
    background: none;
    color: black;
    text-shadow: none;
    padding: 7px 20px;
    line-height: 1.6;
    box-shadow: none;
    font-size: 14px;
    font-weight: 700;
    border-radius: 3px;
    border: 2px solid #84a8c2!important;
    transition: all .1s ease-in-out;
}

.btn-custom-outline-blue:hover{
    color: white;
    background: #84a8c2;
    transition: all .1s ease-in-out;
}


.btn-custom-outline-red:hover{
    color: white;
    background: #ff143b;
    transition: all .1s ease-in-out;
}

.first-section .lead {
    text-align:center;
    margin-right: 30px;
    font-size: 21px;
    font-weight: 300;
    padding: 0 0 40px;
    margin: 0;
    line-height: inherit;
    color: #ffffff;
}

.macbookright {
    width: 980px;
    position: absolute;
    right: -15%;
    bottom: -6%;
}

.section-title {
    display: block;
    position: relative;
    margin-bottom: 60px;
}

.section-title p {
    color: #999;
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    margin: 0;
}

.section-title h3 {
    font-size: 42px;
    font-weight: 300;
    line-height: 62px;
    margin: 0 0 25px;
    padding: 0;
    text-transform: none;
}

.section.colorsection p,
.section.colorsection h3,
.section.db h3 {
    color: #ffffff;
}


body.seo_version .btn-light.ban-btn{
	padding: 13px 40px;
	font-size: 18px;
	border: 2px solid #ffffff;
	color: #ffffff;
	background-color: #121212;
}

.who{
	padding: 15px 10px;
	box-shadow: 0 0 15px rgba(0,0,0,0.14);
}

.progress-bar-success{
	background: #ff143b;
	box-shadow: none;
}




/*------------------------------------------------------------------
    PORTFOLIO
-------------------------------------------------------------------*/

.item-h2,
.item-h1 {
    height: 100% !important;
    height: auto !important;
}

.isotope-item {
    z-index: 2;
    padding: 0;
}

.isotope-hidden.isotope-item {
    pointer-events: none;
    z-index: 1;
}

.isotope,
.isotope .isotope-item {
    /* change duration value to whatever you like */
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    transition-duration: 0.8s;
}

.isotope {
    -webkit-transition-property: height, width;
    -moz-transition-property: height, width;
    transition-property: height, width;
}

.isotope .isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property: -moz-transform, opacity;
    transition-property: transform, opacity;
}

.portfolio-filter ul {
    padding: 0;
    z-index: 2;
    display: block;
    position: relative;
    margin: 0;
}

.portfolio-filter ul li {
    border-radius: 0;
    display: inline-block;
    margin: 0 5px 0 0;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
}

.portfolio-filter ul li:last-child:after {
    content: "";
}

.portfolio-filter ul li .btn-dark {
    box-shadow: none;
    background-color: transparent;
    border: 1px solid #e6e7e6 !important;
    color: #1f1f1f;
    font-weight: 400;
    font-size: 13px;
    padding: 10px 30px;
}

.da-thumbs {
    list-style: none;
    position: relative;
    padding: 0;
}

.da-thumbs .pitem {
    margin: 0;
    padding: 15px;
    position: relative;
}

.da-thumbs .pitem a,
.da-thumbs .pitem a img {
    display: block;
    position: relative;
}

.da-thumbs .pitem a {
    overflow: hidden;
}

.da-thumbs .pitem a div {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
}

.da-thumbs .pitem a div h3 {
    display: block;
    color: #ffffff;
    font-size: 20px;
    padding: 30px 15px;
    text-transform: capitalize;
    font-weight: normal;
}

.da-thumbs .pitem a div h3 small {
    display: block;
    color: #ffffff;
    margin-top: 5px;
    font-size: 13px;
    font-weight: 300;
}

.da-thumbs .pitem a div i {
    background-color: #1f1f1f;
    position: absolute;
    color: #ffffff !important;
    bottom: 0;
    font-size: 15px;
    z-index: 12;
    right: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
}

.da-thumbs .pitem a div p{
	padding: 0px 15px;
}

.da-thumbs .pitem a div h3{
	padding: 10px 15px;
}


/*------------------------------------------------------------------
    TESTIMONIALS
-------------------------------------------------------------------*/

.logos img {
    margin: auto;
    display: block;
    text-align: center;
    width: 100%;
    opacity: 0.3;
}

.logos img:hover {
    opacity: 0.5;
}

.desc h3 i {
    color: #2f2f2f;
    font-size: 37px;
    vertical-align: middle;
    margin-right: 12px;
}

.desc {
    padding: 30px;
    position: relative;
    background: #000;
    border: 1px solid #111111;
}

.testi-meta {
    display: block;
	max-width: 250px;
	margin: 0 auto;
	margin-top: 20px;
}

.testimonial h4 {
    font-size: 18px;
    color: #ffffff;
    padding: 13px 0 0;
}

.testimonial img {
    max-width: 55px;
}

.testimonial small {
    margin-top: 7px;
    font-size: 16px;
    display: block;
}

.testimonial {
    background-color: transparent;
	text-align: center;
	max-width: 730px;
	margin: 0 auto;
	border-radius: 4px;
	overflow: hidden;
}

.testimonial h3 {
    padding: 0 0 10px;
    font-size: 20px;
    font-weight: 700;
}

.testimonial small,
.testimonial .lead {
    background-color: transparent;
    color: #aaa;
    display: block;
    font-size: 16px;
    font-style: italic;
    line-height: 30px;
    margin: 0;
    padding: 0;
    position: relative;
}

.testimonial p:after {
    display: none;
}


/*------------------------------------------------------------------
    PRICING TABLES
-------------------------------------------------------------------*/

.pricing-bg{
	background: url("../images/pattern.png");
	position: relative;
}

.pricing-bg::before{
	content: " ";
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(0,0,0,0.5);
	top: 0px;
	left: 0px;
}

.pricing-bg .section-title h3{
	color: #ffffff;
}

.pricing-bg .section-title p{
	color: #ffffff;
}

.pricing-table {
    margin: 0px;
    background: #fff;
    box-shadow: 0 5px 14px rgba(0, 0, 0, 0.1);
}

.pricing-table i {
    width: 30px;
    color: #c2c2c2;
    display: inline-block;
    margin-right: 10px;
    padding-right: 5px;
    border-right: 1px solid #ececec;
}

.pricing-table .btn-dark {
    padding: 10px 24px;
    font-size: 15px;
}

.pricing-table strong {
    font-weight: 600;
    margin-right: 6px;
    color: #1f1f1f;
}

.pricing-table-header {
	position: relative;
    padding: 30px 0 25px 0;
    background: #ffffff;
	border-radius: 100px 0px 100px 50px;
}

.pricing-table-header h2 {
    font-size: 31px;
    margin: 0;
    padding: 0;
    font-weight: 500;
}

.pricing-table-header h3 {
    font-size: 15px;
    font-weight: 500;
    color: #aaaaaa;
    margin-top: 10px;
    text-transform: uppercase;
}

.pricing-table-header span{
	position: absolute;
	right: 0px;
	top: 0px;
	background: #121212;
	padding: 5px 10px;
	text-transform: uppercase;
	box-shadow: 0 5px 14px rgba(0, 0, 0, 0.1);
}

.pricing-table-space {
    height: 10px;
}

.pricing-table-text {
    margin: 15px 30px 0 30px;
    padding: 0 10px 15px 10px;
    border-bottom: 1px solid #ececec;
    font-weight: 300;
    line-height: 30px;
    color: #c2c2c2;
    font-size: 16px;
}

.pricing-table-text p {
    font-weight: 400;
}

.pricing-table-features {
    margin: 15px 30px 0 30px;
    padding: 0px 20px;
    text-align: left;
    line-height: 30px;
    font-size: 16px;
    color: #c2c2c2;
}

.pricing-table-features p{
	border-bottom: 1px solid #ececec;
	padding-bottom: 10px;
}

.pricing-table-highlighted h3,
.pricing-table-highlighted h2 {
    color: #ffffff !important;
}

.pricing-table-sign-up {
    margin-top: 25px;
    padding-bottom: 30px;
}


/* Highlighted table */

.pricing-table-highlighted {
    margin-top: 0;
}

.m130 {
    margin-top: 130px;
}

.nav-pills {
    border: 1px solid #e1e1e1;
}

.nav-pills > li {
    width: 50%;
    padding: 10px;
    float: left;
    margin: 0 !important;
}

.nav-pills > li > a {
    margin: 0!important;
    text-align: center;
    background-color: #f4f4f4;
}


/*------------------------------------------------------------------
    ICON BOXES
-------------------------------------------------------------------*/

.icon-wrapper {
    position: relative;
    cursor: pointer;
    display: block;
    z-index: 1;
}

.icon-wrapper i {
    width: 75px;
    height: 75px;
    text-align: center;
    line-height: 75px;
    font-size: 28px;
    background-color: #f2f3f5;
    color: #1f1f1f;
    margin-top: 0;
}

.small-icons.icon-wrapper:hover i,
.small-icons.icon-wrapper:hover i:hover,
.small-icons.icon-wrapper i {
    width: auto !important;
    height: auto !important;
    line-height: 1 !important;
    padding: 0 !important;
    color: #e3e3e3 !important;
    background-color: transparent !important;
    background: none !important;
    margin-right: 10px !important;
    vertical-align: middle;
    font-size: 24px !important;
}

.small-icons.icon-wrapper h3 {
    font-size: 18px;
    padding-bottom: 5px;
}

.small-icons.icon-wrapper p {
    padding: 0;
    margin: 0;
}

.icon-wrapper h3 {
    font-size: 21px;
    padding: 0 0 15px;
    margin: 0;
}

.icon-wrapper p {
    margin-bottom: 0;
    padding-left: 95px;
}

.icon-wrapper p small {
    display: block;
    color: #999;
    margin-top: 10px;
    text-transform: none;
    font-weight: 600;
    font-size: 16px;
}

.icon-wrapper p small:after {
    content: "\f105";
    font-family: FontAwesome;
    margin-left: 5px;
    font-size: 11px;
}

.effect-1 {
    display: inline-block;
    cursor: pointer;
    text-align: center;
    position: relative;
    text-decoration: none;
    z-index: 1;
}

.effect-1:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
}

.effect-1 {
    -webkit-transition: background 0.2s, color 0.2s;
    -moz-transition: background 0.2s, color 0.2s;
    transition: background 0.2s, color 0.2s;
}

.effect-1:after {
    top: -7px;
    left: -7px;
    padding: 7px;
    box-shadow: 0 0 0 2px #2750ff;
    -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
    -webkit-transform: scale(.8);
    -moz-transition: -moz-transform 0.2s, opacity 0.2s;
    -moz-transform: scale(.8);
    -ms-transform: scale(.8);
    transition: transform 0.2s, opacity 0.2s;
    transform: scale(.8);
    opacity: 0;
}

.effect-1:hover:after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}

.effect-1:after {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
}

.effect-1:hover:after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}


/*------------------------------------------------------------------
    MESSAGE BOXES
-------------------------------------------------------------------*/

.service-widget h3 {
    font-size: 21px;
    color: #ffffff;
    padding: 20px 0 12px;
    margin: 0;
}

.service-widget h3 a,
.section.wb .service-widget h3,
.section.lb .service-widget h3 {
    color: #1f1f1f;
}

.service-widget p {
    margin-bottom: 0;
    padding-bottom: 0;
}

.message-box h4 {
    text-transform: uppercase;
    padding: 0;
    margin: 0 0 5px;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: 15px;
    color: #999;
}

.message-box h2 {
    font-size: 38px;
    font-weight: 300;
    padding: 0 0 10px;
    margin: 0;
    line-height: 62px;
    margin-top: 0;
    text-transform: none;
}

.message-box p {
    margin-bottom: 20px;
}

.message-box .lead {
    padding-top: 10px;
    font-size: 19px;
    font-style: italic;
    color: #999;
    padding-bottom: 0;
}

.service-widget{
	padding: 20px 15px;
	border: 1px solid #f2f2f2;
	background-color: #ffffff;
	-webkit-box-shadow: 0px 2px 16px 0px rgba(204,204,204,1);
	-moz-box-shadow: 0px 2px 16px 0px rgba(204,204,204,1);
	box-shadow: 0px 2px 16px 0px rgba(204,204,204,1);
}

.owl-screen{
	padding: 10px;
}

.post-media {
    position: relative;
}

.post-media img {
    width: 100%;
}

.playbutton {
    position: absolute;
    color: #ffffff !important;
    top: 40%;
    font-size: 60px;
    z-index: 12;
    left: 0;
    right: 0;
    text-align: center;
    margin: -20px auto;
}

.hoverbutton {
    background-color: rgba(255, 255, 255, 0.8);
    position: absolute;
    color: #1f1f1f !important;
    top: 48%;
    font-size: 21px;
    z-index: 12;
    left: 0;
    opacity: 0;
    right: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    margin: -20px auto;
}

.service-widget:hover .hoverbutton {
    opacity: 1;
}

hr.hr1 {
    position: relative;
    margin: 60px 0;
    border: 1px dashed #f2f3f5;
}

hr.hr2 {
    position: relative;
    margin: 17px 0;
    border: 1px dashed #f2f3f5;
}

hr.hr3 {
    position: relative;
    margin: 25px 0 30px 0;
    border: 1px dashed #f2f3f5;
}

hr.invis {
    border-color: transparent;
}

hr.invis1 {
    margin: 10px 0;
    border-color: transparent;
}

.section.parallax hr.hr1 {
    border-color: rgba(255, 255, 255, 0.1);
}

.sep1 {
    display: block;
    position: absolute;
    content: '';
    width: 40px;
    height: 40px;
    bottom: -20px;
    left: 50%;
    margin-left: -14px;
    background-color: #1f1f1f;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 1;
}

.sep2 {
    display: block;
    position: absolute;
    content: '';
    width: 40px;
    height: 40px;
    top: -20px;
    left: 50%;
    margin-left: -14px;
    background-color: #1f1f1f;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: 1;
}


/* Divider Styles */

.divider-wrapper {
    width: 100%;
    box-shadow: 0 5px 14px rgba(0, 0, 0, 0.1);
    height: 540px;
    margin: 0 auto;
    position: relative;
}

.divider-wrapper:hover {
    cursor: none;
}

.divider-bar {
    position: absolute;
    width: 10px;
    left: 50%;
    top: -10px;
    bottom: -15px;
}

.code-wrapper {
    border: 1px solid #ffffff;
    display: block;
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;

}

.design-wrapper {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
}

.design-image {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);

}


/*------------------------------------------------------------------
    FEATURES
-------------------------------------------------------------------*/

.customwidget h1 {
    font-size: 44px;
    color: #ffffff;
    padding: 15px 0 25px;
    margin: 0;
    line-height: 1 !important;
    font-weight: 300;
}

.customwidget ul {
    padding: 0;
    display: block;
    margin-bottom: 30px;
}

.customwidget li i {
    margin-right: 5px;
}

.customwidget li {
    color: #ffffff;
    margin-right: 10px;
}

.image-center img {
    position: relative;
    margin: 0 0 -208px;
    z-index: 10;
    padding-right: 30px;
    text-align: center;
}

.customwidget p {
    font-style: italic;
    font-size: 18px;
    padding: 0 0 10px;
}

.img-center img {
    width: 100%;
    box-shadow: 0 5px 14px rgba(0, 0, 0, 0.1);
}

.img-center {
    margin: auto;
}

#features li p {
    margin-bottom: 0;
    padding-bottom: 0;
}

#features li {
    display: table;
    width: 100%;
    margin: 35px 0;
    cursor: pointer;
}

.features-left,
.features-right {
    padding: 0 10px;
}

.features-right li:last-child,
.features-left li:last-child {
    margin-bottom: 0px;
    padding-bottom: 0 !important;
}

.features-right li i,
.features-left li i {
    width: 68px;
    height: 68px;
    line-height: 68px;
    display: table;
    border-radius: 50%;
    font-size: 26px;
    background-color: #f2f3f5;
    margin: 0 auto 22px;
    position: relative;
    text-align: center;
    z-index: 55;
    transition: .4s;
    padding: 0;
}

#features i img {
    display: table;
    margin: 0 auto;
}

.features-left li i:before,
.features-right li i:before {
    text-align: center;
}

.features-right li i .ico-current,
.features-left li i .ico-current {
    opacity: 1;
    transition: .4s;
    visibility: visible;
}

.features-right li i .ico-hover,
.features-left li i .ico-hover {
    opacity: 0;
    transition: .4s;
    visibility: hidden;
    top: 19px;
}

.features-right li:hover .ico-current,
.features-left li:hover .ico-current {
    opacity: 0;
    transition: .4s;
    visibility: hidden;
}

.features-right li:hover .ico-hover,
.features-left li:hover .ico-hover {
    opacity: 1;
    transition: .4s;
    visibility: visible;
}

.features-right i {
    float: left;
}

.fr-inner {
    margin-left: 90px;
}

.features-left i {
    float: right;
}

.fl-inner {
    text-align: right;
    margin-right: 90px;
}

#features h4 {
    text-transform: capitalize;
    margin: 0;
    font-size: 19px;
}


/*------------------------------------------------------------------
    CONTACT
-------------------------------------------------------------------*/

.bootstrap-select {
    width: 100% \0;
    /*IE9 and below*/
}

.bootstrap-select > .dropdown-toggle {
    width: 100%;
    padding-right: 25px;
}

.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle {
    border-color: #b94a48;
}

.bootstrap-select.fit-width {
    width: auto !important;
}

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
    width: 100%;
}

.bootstrap-select .dropdown-toggle:focus {
    outline: thin dotted #333333 !important;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px;
}

.bootstrap-select.form-control {
    margin-bottom: 0;
    padding: 0;
    border: none;
}

.bootstrap-select.form-control:not([class*="col-"]) {
    width: 100%;
}

.bootstrap-select.form-control.input-group-btn {
    z-index: auto;
}

.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="col-"] {
    float: none;
    display: inline-block;
    margin-left: 0;
}

.bootstrap-select.btn-group.dropdown-menu-right,
.bootstrap-select.btn-group[class*="col-"].dropdown-menu-right,
.row .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
    float: right;
}

.form-inline .bootstrap-select.btn-group,
.form-horizontal .bootstrap-select.btn-group,
.form-group .bootstrap-select.btn-group {
    margin-bottom: 0;
}

.form-group-lg .bootstrap-select.btn-group.form-control,
.form-group-sm .bootstrap-select.btn-group.form-control {
    padding: 0;
}

.form-inline .bootstrap-select.btn-group .form-control {
    width: 100%;
}

.bootstrap-select.btn-group.disabled,
.bootstrap-select.btn-group > .disabled {
    cursor: not-allowed;
}

.bootstrap-select.btn-group.disabled:focus,
.bootstrap-select.btn-group > .disabled:focus {
    outline: none !important;
}

.bootstrap-select.btn-group .dropdown-toggle .filter-option {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    text-align: left;
}

.bootstrap-select.btn-group .dropdown-toggle .fa-angle-down {
    position: absolute;
    top: 30% !important;
    right: -5px;
    vertical-align: middle;
}

.bootstrap-select.btn-group[class*="col-"] .dropdown-toggle {
    width: 100%;
}

.bootstrap-select.btn-group .dropdown-menu {
    border: 1px solid #ededed;
    box-shadow: none;
    box-sizing: border-box;
    min-width: 100%;
    padding: 20px 10px;
    z-index: 1035;
}

.dropdown-menu > li > a {
    background-color: transparent !important;
    color: #bcbcbc !important;
    font-size: 15px;
    padding: 10px 20px;
}

.bootstrap-select.btn-group .dropdown-menu.inner {
    position: static;
    float: none;
    border: 0;
    padding: 0;
    margin: 0;
    border-radius: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.bootstrap-select.btn-group .dropdown-menu li {
    position: relative;
}

.bootstrap-select.btn-group .dropdown-menu li.active small {
    color: #fff;
}

.bootstrap-select.btn-group .dropdown-menu li.disabled a {
    cursor: not-allowed;
}

.bootstrap-select.btn-group .dropdown-menu li a {
    cursor: pointer;
}

.bootstrap-select.btn-group .dropdown-menu li a.opt {
    position: relative;
    padding-left: 2.25em;
}

.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
    display: none;
}

.bootstrap-select.btn-group .dropdown-menu li a span.text {
    display: inline-block;
}

.bootstrap-select.btn-group .dropdown-menu li small {
    padding-left: 0.5em;
}

.bootstrap-select.btn-group .dropdown-menu .notify {
    position: absolute;
    bottom: 5px;
    width: 96%;
    margin: 0 2%;
    min-height: 26px;
    padding: 3px 5px;
    background: #f5f5f5;
    border: 1px solid #e3e3e3;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
    pointer-events: none;
    opacity: 0.9;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.bootstrap-select.btn-group .no-results {
    padding: 3px;
    background: #f5f5f5;
    margin: 0 5px;
    white-space: nowrap;
}

.bootstrap-select.btn-group.fit-width .dropdown-toggle .filter-option {
    position: static;
}

.bootstrap-select.btn-group.fit-width .dropdown-toggle .caret {
    position: static;
    top: auto;
    margin-top: 4px;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
    position: absolute;
    display: inline-block;
    right: 15px;
    margin-top: 5px;
}

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
    margin-right: 34px;
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle {
    z-index: 1036;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
    content: '';
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgba(204, 204, 204, 0.2);
    position: absolute;
    bottom: -4px;
    left: 9px;
    display: none;
}

.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid white;
    position: absolute;
    bottom: -4px;
    left: 10px;
    display: none;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
    bottom: auto;
    top: -3px;
    border-top: 7px solid rgba(204, 204, 204, 0.2);
    border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
    bottom: auto;
    top: -3px;
    border-top: 6px solid white;
    border-bottom: 0;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
    right: 12px;
    left: auto;
}

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
    right: 13px;
    left: auto;
}

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:after {
    display: block;
}

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
    padding: 4px 8px;
}

.bs-actionsbox {
    float: left;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.bs-actionsbox .btn-group button {
    width: 50%;
}

.bs-donebutton {
    float: left;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.bs-donebutton .btn-group button {
    width: 100%;
}

.bs-searchbox + .bs-actionsbox {
    padding: 0 8px 4px;
}

.bs-searchbox .form-control {
    margin-bottom: 0;
    width: 100%;
}

select.bs-select-hidden,
select.selectpicker {
    display: none !important;
}

select.mobile-device {
    position: absolute !important;
    top: 0;
    left: 0;
    display: block !important;
    width: 100%;
    height: 100% !important;
    opacity: 0;
}


/*# sourceMappingURL=bootstrap-select.css.map */

.bootstrap-select > .btn {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    font-size: 15px;
    height: 33px;
    box-shadow: none !important;
    border: 0 !important;
    padding: 0;
    width: 100%;
    color: #bcbcbc !important;
}

.contact_form {    
    background-color: #f2f3f5;
    padding: 40px 30px;
}

.contact_form .form-control {
    background-color: #fff;
    margin-bottom: 30px;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    color: #bcbcbc;
    font-size: 16px;
    outline: 0 none;
    padding: 10px 25px;
    height: 55px;
    resize: none;
    box-shadow: none !important;
    width: 100%;
}

.contact_form textarea {
    color: #bcbcbc;
    padding: 20px 25px !important;
    height: 160px !important;
}

.contact_form .form-control::-webkit-input-placeholder {
    color: #bcbcbc;
}

.contact_form .form-control::-moz-placeholder {
    opacity: 1;
    color: #bcbcbc;
}

.contact_form .form-control::-ms-input-placeholder {
    color: #bcbcbc;
}

#contact {
    background: url(../images/bg.png) no-repeat center center #fff;
}


/*------------------------------------------------------------------
    FOOTER
-------------------------------------------------------------------*/

.cac {
    background-color: #232323;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.cac:hover a h3 {
    color: #fff !important;
}

.cac a h3 {
    color: #999;
}

.cac h3 {
    padding: 60px 0;
    margin: 0;
    font-weight: 400;
    font-size: 20px;
    text-transform: capitalize;
    line-height: !important;
}

.footer {
    padding: 0px;
    color: #999;
    background-color: #ffffff;
}

.footer .widget-title {
    position: relative;
    display: block;
    margin-bottom: 30px;
}

.footer .widget-title small {
    color: #999;
    display: block;
    padding: 0 58px;
    text-transform: uppercase;
	position: absolute;
	bottom: 0;
	right: 0;
	width: 90%;
}

.footer .widget-title h3 {
    color: #ff143b;
    font-weight: 500;
    font-size: 21px;
    padding: 0;
    margin: 0;
    line-height: 1 !important;
}

.footer-links {
    list-style: none;
    padding: 0;
}

.footer-links a {
    color: #999;
}

body.seo_version .footer-links a:hover{
	color: #ff143b;
}
.footer-links a:hover,
.footer a:hover {
    color: #ff143b;
}

.footer-links li {
    margin-bottom: 10px;
    display: block;
    width: 100%;
    padding-bottom: 10px;
}

.twitter-widget li {
    margin-bottom: 0;
    border: 0 !important;
}

.twitter-widget li i {
    border-right: 0 !important;
    margin-right: 0;
}

.footer-links li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: 0;
}

.footer-links i {
    display: inline-block;
    width: 25px;
    margin-right: 10px;
    border-right: 1px dashed rgba(255, 255, 255, 0.1);
}

.copyrights {
    border-top: 1px dashed rgba(255, 255, 255, 0.1);
    background-color: #1f1f1f;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    padding: 50px 60px;
    overflow: hidden;
}


/* Footer left */

.footer-distributed .footer-left {
    float: left;
}

.footer-distributed .footer-links {
    margin: 0 0 10px;
    text-transform: uppercase;
    padding: 0;
}

.footer-distributed .footer-links a {
    display: inline-block;
    line-height: 1.8;
    margin: 0 10px 0 10px;
    text-decoration: none;
}

.footer-distributed .footer-company-name {
    font-weight: 300;
    margin: 0 10px;
    color: #666;
    padding: 20px 0px;
}


/* Footer right */

.footer-distributed .footer-right {
    float: right;
}


/* The search form */

.footer-distributed form {
    position: relative;
}

.footer-distributed form input {
    display: block;
    border-radius: 3px;
    box-sizing: border-box;
    background-color: #181818;
    border: none;
    font: inherit;
    font-size: 15px;
    font-weight: normal;
    color: #999;
    width: 400px;
    padding: 18px 50px 18px 18px;
}

.footer-distributed form input:focus {
    outline: none;
}


/* Changing the placeholder color */

.footer-distributed form input::-webkit-input-placeholder {
    color: #999;
}

.footer-distributed form input::-moz-placeholder {
    opacity: 1;
    color: #999;
}

.footer-distributed form input:-ms-input-placeholder {
    color: #999;
}


.footer-distributed .footer-company-name a{
	color: #111111;
}

.footer-distributed .footer-company-name a:hover{
	color: #ff143b;
}



/* The magnify glass icon */

.footer-distributed form i {
    width: 18px;
    height: 18px;
    position: absolute;
    top: 16px;
    right: 18px;
    color: #999;
    font-size: 18px;
    margin-top: 6px;
}

.social-md li a.fb{
	background: #3b579d;
	display: block;
	color: #ffffff !important;
	padding: 5px 15px;
}
.social-md li a.gi{
	background: #000000;
	display: block;
	color: #ffffff !important;
	padding: 5px 15px;
}
.social-md li a.tw{
	background: #00aced;
	display: block;
	color: #ffffff !important;
	padding: 5px 15px;
}
.social-md li a.dr{
	background: #ea4c89;
	display: block;
	color: #ffffff !important;
	padding: 5px 15px;
}
.social-md li a.pi{
	background: #cb2026;
	display: block;
	color: #ffffff !important;
	padding: 5px 15px;
}

.social-md li a.fb:hover{
	background: #ff143b;
}
.social-md li a.gi:hover{
	background: #ff143b;
}
.social-md li a.tw:hover{
	background: #ff143b;
}
.social-md li a.dr:hover{
	background: #ff143b;
}
.social-md li a.pi:hover{
	background: #ff143b;
}


/*------------------------------------------------------------------
    MISC
-------------------------------------------------------------------*/

.progress {
    background-color: #f2f3f5;
    border-radius: 0;
    box-shadow: none;
    height: 5px;
    margin-bottom: 20px;
    overflow: hidden;
}

.skills h3 {
    color: #999999;
    font-size: 15px;
}

.dmtop {
    background-color: #3C3D41;
    z-index: 100;
    width: 50px;
    height: 50px;
    line-height: 47px;
    position: fixed;
    bottom: -100px;
    border-radius: 3px;
    right: 50px;
    text-align: center;
    font-size: 28px;
    color: #ffffff !important;
    cursor: pointer;
    -webkit-transition: all .7s ease-in-out;
    -moz-transition: all .7s ease-in-out;
    -o-transition: all .7s ease-in-out;
    -ms-transition: all .7s ease-in-out;
    transition: all .7s ease-in-out;
}

.icon_wrap {
    background-color: #1f1f1f;
    width: 100px;
    height: 100px;
    display: block;
    line-height: 100px;
    font-size: 34px;
    color: #ffffff;
    margin: 0 auto;
    text-align: center;
    padding: 0 !important;
    border: 0 !important;
}

.stat-wrap h3 {
    font-size: 18px;
    font-weight: 400;
    color: #999 !important;
    margin: 0 !important;
    padding: 0 !important;
    line-height: 1!important;
}

.stat-wrap p {
    font-size: 38px;
    color: #ffffff;
    margin: 0;
    font-weight: 300;
    padding: 4px 0 0;
    line-height: 1 !important;
}

#preloader {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #fff;
    z-index: 11000;
    position: fixed;
    display: block
}

.preloader {
    position: absolute;
    margin: 0 auto;
    left: 1%;
    right: 1%;
    top: 45%;
    width: 95px;
    height: 95px;
    background: center center no-repeat none;
    background-size: 95px 95px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 50%
}


/*------------------------------------------------------------------
    BUTTONS
-------------------------------------------------------------------*/

.btn-custom-primary-outline{
    background: none;
    color: #fff;
    text-shadow: none;
    padding: 9px 22px;
    line-height: 1.6;
    box-shadow: none;
    font-size: 14px;
    font-weight: 700;
    border-radius: 3px;
    border: 2px solid #fff!important;
    transition: all .1s ease-in-out;
}

.btn-custom-primary-outline:hover{
    transition: all .1s ease-in-out;
    color: white!important;
    border: 2px solid #ff143b!important;

}

.btn-custom-secondary-outline{
    background: none;
    color: #84a8c2;
    text-shadow: none;
    padding: 9px 22px;
    line-height: 1.6;
    box-shadow: none;
    font-size: 14px;
    font-weight: 700;
    border-radius: 3px;

    border: 2px solid #fff!important;
    transition: all .1s ease-in-out;
}

.btn-custom-secondary-outline:hover{
    transition: all .1s ease-in-out;
    color: white!important;
    border: 2px solid #84a8c2!important;

}

.btn-custom-primary{
    background:  #ff143b!important;
    color: #fff;
    text-shadow: none;
    padding: 9px 22px;
    line-height: 1.6;
    box-shadow: none;
    font-size: 14px;
    font-weight: 700;
    border-radius: 3px;
    border: 2px solid #ff143b!important;
    transition: all .1s ease-in-out;
}

.btn-custom-primary:hover{
    background: rgba(217, 20, 54, 0.86);
    color: #fff;
    text-shadow: none;
    padding: 9px 22px;
    line-height: 1.6;
    box-shadow: none;
    font-size: 14px;
    font-weight: 700;
    border-radius: 3px;
    border: 2px solid rgba(217, 20, 54, 0.86)!important;
    transition: all .1s ease-in-out;
}

.btn-custom-secondary{
    background:  #84a8c2;
    color: #fff;
    text-shadow: none;
    padding: 9px 22px;
    line-height: 1.6;
    box-shadow: none;
    font-size: 14px;
    font-weight: 700;
    border-radius: 3px;
    border: 2px solid #84a8c2!important;
    transition: all .1s ease-in-out;
}

.btn-custom-secondary:hover{
    background: rgba(113, 146, 171, 0.92);
    color: #fff;
    text-shadow: none;
    padding: 9px 22px;
    line-height: 1.6;
    box-shadow: none;
    font-size: 14px;
    font-weight: 700;
    border-radius: 3px;
    border: 2px solid rgba(113, 146, 171, 0.92)!important;
    transition: all .1s ease-in-out;
}


.navbar-default .btn-light {
    padding: 0 20px;
    margin-left: 15px;
}

.nav-pills,
.effect-1:after,
.nav-pills > li > a,
.global-radius,
.btn-brd {
    border-radius: 0px;
}

.btn-light {
    padding: 13px 40px;
    font-size: 18px;
    border: 2px solid #ffffff;
    color: #ffffff;
    background-color: transparent;
}

.btn-dark {
    padding: 13px 40px;
    font-size: 18px;
    border: 1px solid #ececec !important;
    color: #1f1f1f;
    background-color: transparent;
}

.btn-light:hover,
.btn-light:focus {
    border-color: rgba(255, 255, 255, 0.6);
    color: rgba(255, 255, 255, 0.6);
}



/**-------------- inner Page css ---------------**/


.all-page-title{
	background-size: cover;
	background-color: #ff143b;
	background-repeat: no-repeat;
	padding: 260px 0px 110px 0px;
	background-position: center bottom;
}

.all-page-title h1{
	position: relative;
	font-size: 36px;
	font-weight: 700;
	color: #ffffff;
	text-align: center;
	line-height: 1.3em;
	margin-bottom: 10px;
	text-transform: capitalize;
}

.page-info{
	text-align: center;
}
.inner-container ul li{
	display: inline-block;
	color: #121212;
}
.inner-container ul li span{
	background: #121212;
	display: inline-block;
	color: #ffffff;
	padding: 5px 10px;	
}

.inner-container ul li a{
	display: inline-block;
	color: #121212;
	padding: 0px 5px;
}
.inner-container ul li a:hover{
	color: #ffffff;
}


.map-box{
	padding: 40px 30px;
}
.small-map {
    width: 100%;
    height: 500px;
    margin: 0 auto auto;
    box-shadow: 10px 10px 15px 0px #ccc;
}







.card-login{
    height: 370px;
    margin-top: auto;
    margin-bottom: auto;
    width: 400px;
    background-color: rgba(0,0,0,0.5) !important;
}

.social_icon span{
    font-size: 60px;
    margin-left: 10px;
    color: #ff143b;
}

.social_icon span:hover{
    color: white;
    cursor: pointer;
}

.card-header h3{
    color: white;
}

.social_icon{
    position: absolute;
    right: 20px;
    top: -45px;
}

.input-group-prepend span{
    width: 50px;
    background-color: #ff143b;
    color: white;
    border:0 !important;
}

input:focus{
    outline: 0 0 0 0  !important;
    box-shadow: 0 0 0 0 !important;

}

.remember{
    color: white;
}

.remember input
{
    width: 20px;
    height: 20px;
    margin-left: 15px;
    margin-right: 5px;
}

.login_btn{
    color: white;
    background-color: #ff143b;
}

.login_btn:hover{
    color: black;
    background-color: white;
}

.links{
    color: white;
}

.links a{
    margin-left: 4px;
}


.login-container{
    margin-bottom: 150px;
    height: 400px;
    width: 400px;
}



.register-container{
    background-color: #f2f3f5!important;
}

.form-control:focus {
    border-color: #ff143b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(242, 243, 245, 0.6);
}


.primary-color{
    color: #ff143b;
}

.secondary-color{
    color: #84a8c2;
}


.information-under-header{
    background-color: white;
}

.peak{
    background-color: #CD2122;
    -webkit-transform: skewY(-1.8deg);
    -ms-transform: skewY(-1.8deg);
     transform: skewY(-1.8deg);
    border-radius: 6px;
    transform-origin: right top;
    margin-top: -80px;
    margin-bottom: 80px;

}
.peak:before{
    position: absolute;
    content: '';
    background-color: #CD2122;
    width: 30px;
    height: 32px;
    display: block;
    bottom: -6px;
    left: 20%;
    border-radius: 4px;
    -webkit-transform: skewY(35deg);
    -ms-transform: skewY(35deg);
    transform: skewY(35deg);
    border-top: 0;
}
.text-peak{
    color: white;
    font-size: 18px!important;
    margin: 2rem;
    -webkit-transform: skewY(1.8deg);
    -ms-transform: skewY(1.8deg);
    transform: skewY(1.8deg);
}

.peak-button{
    margin: 3rem;
    background: white;
    color: black!important;
    width: auto;
    -webkit-transform: skewY(1.8deg);
    -ms-transform: skewY(1.8deg);
    transform: skewY(1.8deg);
}
.peak-button:hover{
    color: black!important;
}

.how-it-works{
    background-color: #f2f3f5;
    margin-bottom: 40px;
}

.icon{
    width: 80px;
    height: 80px;
}
.icon-arrow{
    width: 40px;
    height: 40px;
    margin-top: 40px;
}

.icon-text{
    font-weight: bold;
    font-size: 16px;
    color: black;
    margin-top: 20px;
}


.custom-hr-icons{
    width: 70%;
    color: #cc1f1f;
    background-color: #d01616;
    opacity: 0.8;
}
.custom-hr-offers{
    opacity: 0.8;
    width: 70%;
}

#about{
    background-color:  #f2f3f5;
}

.search-row{
    background-color: #84a8c2;
    height: 100px;
}
.search-text{
    font-size: 24px;
    color: white;
}

.our-services-header{
    font-size: 28px;
    color: #7f7f7f;
    font-weight: bold;
}
.dots{
    font-size: 40px;
}

.service-box{
    background-color: #eeeeee;
    position: relative;
}
.icon-box{

}
.service-icon{
    font-size: 50px;
    line-height: 40px;
    color: #cc1f1f;
    margin: 30px;
}
.header-service{
    margin-left: 30px;
}
.header-service-text{
    color: #323232;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.3;
    vertical-align: middle;
    z-index: 2;
    position: relative;
}
.service-box-list{
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 5;
    bottom:0;
    height: 100%;
    position: relative;
    background-color: white;
}
.service-box:hover{
    -webkit-box-shadow: 3px 0px 24px -1px rgba(0,0,0,0.58);
    -moz-box-shadow: 3px 0px 24px -1px rgba(0,0,0,0.58);
    box-shadow: 3px 0px 24px -1px rgba(0,0,0,0.58);
    background-color: white;

}
.service-box-wrapper{
    max-width: 100%;
    min-width: 100%;
}
#service-list-2{

    width: 100%;
    top: -50px;
    display:none;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
}
.service-box-wrapper{
    background-color: white;
}
.text-custom{
    color: #cc1f1f!important;
    margin-left: 30px;
    margin-right: 20px;
}

.content p {
    margin-left: 30px;
    margin-right: 20px;
}
.content{
    padding-bottom: 20px;
}
.header-intro{
    font-family: "Open Sans", Helvetica, Arial, sans-serif;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    color: #cccccc;
    font-style: normal;
    margin-bottom: 20px;
}

.bottom-box-shadow:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    border-radius: 50%;
    z-index: -1;
    box-shadow: 0 8px 10px -10px rgba(0, 0, 0, 0.5);
}

.counter-border{
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
}
.lower{
    border-bottom: 1px solid black;
}
.no-left{
    border-left: none!important;
}

.icon-counter{
    width: 70px;
    height: 70px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.counter{
    margin-top: 10px;
    font-weight: bold;
    font-size: 17px;
    color: black;
}
.counter-section{
    background-color: #f5f5f5;
    height: 400px;

}
.custom-padding-top{
    padding-top: 70px;
}
.hr-before {
    border-top: 1px solid #ff143b;
    position:relative;
    width: 100%;
}


.hr{
    width: 10%;
    float: left;
    box-sizing: border-box;
}
.hr-second{
    width: 90%;
    float: left;
    box-sizing: border-box;
}

.custom-header-all-pages{
    clip-path: polygon(0 0, 0 0, 35% 0%, 65% 0%, 100% 0, 100% 0, 100% 18%, 100% 49%, 50% 66%, 0 49%, 0 0, 0 10%);
    height: 40vh;
}


@media
only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px)  {

    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr {
        display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    tr { border: 1px solid #ccc; }

    td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;

    }

    td:before {
        /* Now like a table header */

        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
    }

    /*
    Label the data
    */
    td:nth-of-type(1):before { content: "Miasto:"; font-weight: bold; }
    td:nth-of-type(2):before { content: "Nip:"; font-weight: bold; }
    td:nth-of-type(3):before { content: "Kwota zadłużenia:";font-weight: bold; }
    .search-text{
        font-size:16px;
    }
}

.about-our-company{
    background-color: white;
}
