@import url("https://fonts.googleapis.com/css?family=Dosis");
* {
    box-sizing: border-box;
}
h1 i {
    color: #EA1438;
}
.timeline-wrapper {
    position: relative;
}
.timeline-wrapper::after {
    content: "";
    position: absolute;
    height: 10px;
    transition: 0.75s ease-out;
    background: #EA1438;
    width: 0px;
    z-index: 0 !important;
    left: 0;
    bottom: 0px;
}
.timeline {
    display: flex;
    transition: all 0.3s;
    justify-content: center;
    padding-bottom: 30px;
    border-bottom: 10px solid #ff143b;
    margin: 0 0 50px 0;
}
.event {
    background: #caecfd;
    cursor: pointer;
    padding: 1em;
    border-radius: 5px;
    position: relative;
    margin: 0 1em 0 1em;
    transition: all 0.3s;
    box-shadow: 0 0 5px #ccc;
    color: #891224;
    text-align: center;
}
 .event .close {
    position: absolute;
    bottom: 10px;
    right: 10px;
    text-align: center;
    border-radius: 3px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: 0.2s;
}
 .event .close:hover {
    color: darkred;
    background: #fff;
}
 .event h2 {
    font-family: Dosis;
    position: relative;
    z-index: 1;
}
 .event h2::before {

    display: block;
    position: absolute;
    top: 50px;
    left: calc(50% - 15px);
    font-size: 30px;
    font-family: "FontAwesome";
    color: #ff143b;
    opacity: 0.1;
    z-index: 0;
}
.event p {
    color: #ff143b;
}
.event * {
    padding: 0;
    margin: 0;
}
 .event:hover {
    background: #98dbfc;
    box-shadow: 0px 0px 10px #ccc;
    transform-origin: 100px 0px;
    transform: rotate(-10deg);
    z-index: 2;
}
 .event:hover::after {
    border-color: #B4142D;
    z-index: 2;
}
 .event::after {
    margin: 0 auto;
    position: relative;
    bottom: -65px;
    content: "";
    display: block;
    border-radius: 15px;
    height: 30px;
    width: 30px;
    box-sizing: border-box;
    box-shadow: 0 0 5px #B4142D;
    background: #fff;
    border: 5px solid #ff143b;
    transition: all 0.25s;
    z-index: 2;
}
 .event.active {
    background: #ffffe0;
    transform: scale(1.1) translateY(-5px);
    box-shadow: 0 0 10px #ccc;
    z-index: 2;
}
 .event.active::after {
    border-color: #EA1438;
    transform: scale(1.1);
}
.timeline .event.previous {
    background: rgba(0, 0, 0, 0.05);
    color: rgba(0, 0, 0, 0.1);
}
.timeline .event.previous p {
    color: rgba(0, 0, 0, 0.1);
}
.timeline .event.previous:hover {
    opacity: 1;
}
